import { Config } from '../../interfaces/config.interface';
import Logo from './logo.png';

const config: Config = {
  name: 'Arcos dorados',
  title: 'Arcos Dorados DeI',
  logo: Logo,
  logo_width: '220px',
  map_hide: false,
  map_top: false,
  withoutLogin: true,
  has_hours: false,
  activities: {
    card: {
      weAreIn: true,
    },
    postulate: {
      comment: true,
    },
    tabs: {
      recording: false,
    },
  },
  classroom: {
    min_approved_score: 99,
  },
  has_reminder: true,
};

export default config;
